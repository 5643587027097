import store from '@/store';
// import moment from "moment";
import consts from "@/consts";

const defaultSetRoute = {
    shipment: (route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipment', route);
            return false;
        }
        router.push({
            name: consts.routerNames?.shipment.main,
            params: {},
            query: {...route.query}
        })
    },
    shipmentId: (route, router, shipmentId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentId', route);
            return false;
        }

        let query = route.query;
        let shipments = store.getters.tiveShipments
            .filter(s =>
                (
                    query.status === 'completed' ?
                        consts?.shipment?.extendedShipmentByStatus?.includes(s.status) :
                        !consts?.shipment?.extendedShipmentByStatus?.includes(s.status)
                )
                && s.id
            )
            .map(s => s.id);

        if(shipments.includes(shipmentId)) {
            let localeStorageKey = store.getters.getUserStorageKey + '.shipmentId'
            localStorage.setItem(localeStorageKey, shipmentId)
        }

        router.push({
            name: consts.routerNames?.shipment.id,
            params: {
                shipmentId,
            },
            query,
        })
    },
    shipmentEdit: (route, router, shipmentId, edit) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentEdit', route);
            return false;
        }

        router.push({
            name: route.name,
            params: {
                ...route.params,
                shipmentId,
                edit: edit ? 'edit' : null
            },
            query: {...route.query}
        })
    },
    shipmentEditView: (route, router, shipmentId) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentEdit', route);
            return false;
        }

        router.push({
            name: consts.routerNames.shipment.editView,
            params: {
                ...route.params,
                shipmentId,
            },
            query: {...route.query}
        })
    },
    shipmentTrackPoint: (route, router, shipmentTrackPoint) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentTrackPoint', route);
            return false;
        }

        router.push({
            name: route.name,
            params: {
                ...route.params,
                shipmentTrackPoint,
            },
            query: {...route.query}
        })
    },
    shipmentSwitch: (route, router, shipmentId, routeName) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentId', route);
            return false;
        }
        router.push({
            name: routeName,
            params: {
                ...route.params,
                shipmentId,
            },
            query: {...route.query}
        })
    },
    returnToParent:(route, router) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.shipment.main+' returnToParent', route);
            return false;
        }

        let localeStorageKey = store.getters.getUserStorageKey + '.shipmentId'
        let shipmentId = localStorage.getItem(localeStorageKey)
        let shipments = store.getters.tiveShipments.filter(s => !consts?.shipment?.extendedShipmentByStatus?.includes(s.status) && s.id);
        let shipment = shipments.find(s => s.shipmentId == shipmentId) || shipments[0]
        shipmentId = shipment?.id
        let name = route.meta?.parentRouteName || consts.routerNames.shipment.main;

        router.push({
            name: name,
            params: {
                ...route.params,
                shipmentId,
                edit: route?.params?.edit ? null : 'edit'
            },
            query: {...route.query}
        })
    },
    shipmentFilter: (route, router, filter) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipmentFilter', route);
            return false
        }
        router.push({
            name: route.name,
            params: {
                ...route.params
            },
            query: {
                ...filter
                // name: filter?.name || undefined,
                // type: filter?.type || undefined,
                // mode: filter?.mode || undefined,
            }
        })
    },
    liteTemplate: (route, router, lite, noList=false) => {
        if(route.meta?.mainRouteName !== consts.routerNames.shipment.main) {
            if(this.$isDevelopment) console.warn('shipment', route);
            return false;
        }
        router.push({
            name: route.name,
            params: {
                ...route?.params,
                noList: noList ? 'no-list' : '',
            },
            query: {...route.query}
        })
    },
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: 'shipment/:noList(no-list)?',
        name: consts.routerNames.shipment.main,
        meta: {
            list: "ShipmentList",
            mainRouteName: consts.routerNames.shipment.main,
            pageLayout: 'shipment',
            showMap: true,
            weatherInMap: true,
            mapType: 'Google-Hybrid',
            unitTracking: true,
            layers: {
                ...consts.defaultSettingsLayers,
                shipments_tracks: {visible: true, showInList: false, showLayer: true},
                shipments_units: {visible: true, showInList: false, showLayer: true},
            },
            setRoute: defaultSetRoute,
        },
        props: {
            default: (route) => ({
                shipmentId: route?.params?.shipmentId
            })
        },
        components: {
            default: () => import('@/views/intuit/portal/ShipmentPage.vue')
        },
        children: [
            {
                path: ':shipmentId(\\d+)?/:edit(edit)?/:shipmentTrackPoint(start|end)?',
                name: consts.routerNames.shipment.id,
                meta: {
                    mainRouteName: consts.routerNames.shipment.main,
                    parentRouteName: consts.routerNames.shipment.id,
                    pageClass: 'shipment__view',
                    weatherInMap: true,
                    // sectionInfoByRoute: (route) => {
                    //     return !!route?.params?.edit
                    // },
                },
                props: {
                    default: (route) => ({
                        shipmentId: route.params.shipmentId
                    }),
                    // sectionInfoByRoute: (route) => ({
                    //     shipmentId: route.params.shipmentId == 0 ? 0 : route.params.shipmentId,
                    //     time: Date.now(),
                    // })
                },
                components: {
                    default: () => import('@/components/shipment/ShipmentView.vue'),
                    // sectionInfoByRoute: () => import('@/components/shipment/sectionInfo/ShipmentEdit.vue'),
                },
                children: [
                    {
                        path: 'map',
                        name: consts.routerNames.shipment.map,
                        meta: {
                            mainRouteName: consts.routerNames.shipment.main,
                            parentRouteName: consts.routerNames.shipment.id,
                            pageClass: 'shipment__view shipment__map',
                            weatherInMap: true,
                            sectionInfoByRoute: (route) => {
                                return !!route?.params?.edit
                            },
                        },
                        props: {
                        },
                        components: {
                        },
                        children: [
                        ]
                    },
                ]
            },
            {
                path: 'edit/:shipmentId(\\d+)?/:shipmentTrackPoint(start|end)?',
                name: consts.routerNames.shipment.editView,
                meta: {
                    list: false,
                    mainRouteName: consts.routerNames.shipment.main,
                    pageClass: 'shipment__view-edit',
                },
                props: {
                    default: (route) => ({
                        shipmentId: route?.params?.shipmentId
                    })
                },
                components: {
                    default: () => import('@/components/shipment/sectionInfo/ShipmentEdit.vue'),
                },
            },
        ]
    },
];

export default routes;