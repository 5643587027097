// import store from '@/store';
// import moment from "moment";
import consts from "@/consts";
import {getMainParentRouteName} from "@/lib/lib";
import store from "@/store";
const tabsParams = {
    fleet: (route, groupId) => {
        return {
            name: consts.routerNames.md.fleet.main,
            params: {
                groupId,
            },
            query: {...route.query}
        }
    },
    vision: (route, groupId) => {
        groupId = groupId || route?.params?.groupId
        return {
            name: consts.routerNames.md.vision.main,
            params: {
                groupId,
            },
            query: {...route.query}
        }
    },
}
const defaultSetRoute = {
    md: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        router.push({
            name: consts.routerNames.md.main,
            params: {
                groupId
            },
            query: {...route.query}
        })
    },
    MDFleet: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        router.push(tabsParams.fleet(route, groupId))
    },
    MDVision: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        router.push(tabsParams.vision(route, groupId))
    },
    MDVisionGroup: (route, router, groupId) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }

        let localeStorageKey = store.getters.getUserStorageKey + '.MDVisionGroup'
        localStorage.setItem(localeStorageKey, groupId)

        router.push({
            name: consts.routerNames?.md?.vision?.main,
            params: {
                groupId,
            },
            query: {...route.query}
        })
    },
    unitsWidget: (route, router, widget) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if(this.$isDevelopment) console.warn(consts.routerNames.md.main+' returnToParent', route);
            return false;
        }
        let groupId = route.params.groupId
        router.push({
            name: consts.routerNames.md.fleet.widget,
            params: {
                widget,
                groupId,
            },
            query: {...route.query}
        })
    },
    groupFilter: (route, router, filter) => {
        if(getMainParentRouteName(route.meta?.mainRouteName) !== consts.routerNames.md.main) {
            if (this.$isDevelopment) console.warn('groupFilter', route);
            return false
        }
        router.push({
            name: route.name,
            params: {
                ...route.params
            },
            query: {...filter}
        })
    }
}

const routes = (process.env.VUE_APP_PACKAGE !== 'intuit.portal') ? [] : [
    {
        path: 'md',
        name: consts.routerNames.md.main,
        meta: {
            mainRouteName: consts.routerNames.md.main,
            pageClass: 'unit-md-page',
            layers: consts.defaultSettingsLayers,
            setRoute: defaultSetRoute,
            // showRouteTabs: true,
            // routeTabs: [
            //     {title: 'Fleet', route: (route) => tabsParams.fleet(route)},
            //     {title: 'Vision', route: (route) => tabsParams.vision(route)},
            // ],
        },
        component: () => import('@/views/intuit/portal/MdPage.vue'),
        children: [
            {
                path: 'fleet/:groupId(\\d+)?',
                name: consts.routerNames.md.fleet.main,
                meta: {
                    mainRouteName: consts.routerNames.md.main,
                    pageClass: 'unit-md-page',
                    layers: consts.defaultSettingsLayers,
                    setRoute: defaultSetRoute,
                    showRouteTabs: true
                },
                props: () => ({
                    excludeWidgets: ['fuel', 'traffic'],
                }),
                component: () => import('@/components/units/info/UnitsInfo_md'),
                children: [
                    {
                        path: ':widget',
                        name: consts.routerNames.md.fleet.widget,
                        meta:{
                            pageClass: 'unit-md-page unit-md-page__full',
                            mainRouteName: consts.routerNames.md.main,
                            parentRouteName: consts.routerNames.md.fleet.main,
                        },
                    }
                ]
            },
            {
                path: 'vision/:groupId(\\d+)?',
                name: consts.routerNames.md.vision.main,
                meta: {
                    list: 'GroupsList_md',
                    mainRouteName: consts.routerNames.md.main,
                    parentRouteName: consts.routerNames.md.vision.main,
                    pageClass: 'unit-md-page',
                    layers: consts.defaultSettingsLayers,
                    setRoute: defaultSetRoute,
                    showRouteTabs: false,
                },
                props: {
                    default: (route) => ({
                        groupId: route?.params?.groupId
                    })
                },
                components: {
                    default: () => import("@/components/md/MDVision.vue")
                },
                children: [
                    {
                        path: ':groupId(\\d+)',
                        name: consts.routerNames.md.vision.groupId.main,
                        meta: {
                            mainRouteName: consts.routerNames.md.main,
                            parentRouteName: consts.routerNames.md.vision.main,
                        },
                    },
                ]
            },
        ]
    },
];

export default routes;